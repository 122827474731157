@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 768px;
    min-height: 768px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }

  @media (max-width: 767px) {
    min-height: 580px;
    height: auto;
    max-height: none;
  }
  @media (max-width: 575px) {
    min-height: 490px;
  }
}
/* .threeIcons {
  background-color: #f3f4f5;
  display: flex;
  width: full;
  justify-content: center;
  align-items: center;
} */
.hero {
  flex-grow: 1;
  justify-content: flex-end;
  /* padding-bottom: 32px; */

  /* @media (--viewportMedium) {
    padding-bottom: 83px;
  } */

  @media (--viewportLarge) {
    justify-content: center;
    /* padding-top: 60px; */
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  background-color: #f2f1ef;
  overflow: hidden;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: 20px 24px 51px 150px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 20px 24px 60px 150px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: 20px auto 150px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
  @media (max-width: 1199px) {
    margin: 0 auto 150px;
    padding: 0 15px;
  }
}
.sectionFooterTop {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}
.sectionContentFirstChild {
  composes: sectionContent;
  @media (max-width: 767px) {
    margin-bottom: 120px;
  }
  /* margin-top: 2vh; */
}
.FooterTop {
  background-size: cover;
}
/* A bar on top of light sections */
.abtsecrow {
  /* padding: 0 0 80px; */
}
.abtsecrow svg path {
  fill: #fff;
}
.dnerow {
  margin: 40px auto 50px;
  max-width: 100%;
  & .redmainHeading {
    margin-top: -5px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
    & .redmainHeading {
      font-size: 27px;
    }
  }
}
.dnerow h2,
.tratrow h1 {
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #232323;
  margin: 0 0 40px;
  line-height: 60px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 40px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
}
.tratrow {
  margin: 30px auto;
  max-width: 100%;
}
.hatwrow {
  margin-bottom: 200px;
  max-width: 100%;
  @media (max-width: 575px) {
    margin-bottom: 50px;
  }
}
.footlast svg path {
  fill: #002435;
}
.testislider {
  /* margin-bottom: 60px; */
}
.mainHeading {
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #232323;
  max-width: 74%;
  margin: 0 auto;
  line-height: 60px;
  @media (max-width: 991px) {
    font-size: 35px;
  }
  @media (max-width: 575px) {
    margin-bottom: 15px;
    max-width: 100%;
    line-height: 34px;
    font-size: 25px;
  }
}
.redmainHeading {
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #c93b54;
  max-width: 74%;
  margin: 0 auto;
  line-height: 60px;
  @media (max-width: 991px) {
    font-size: 35px;
  }
  @media (max-width: 575px) {
    margin-bottom: 15px;
    max-width: 100%;
    line-height: 34px;
    font-size: 25px;
  }
}
.redsub {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  color: #c93b54;
  max-width: 74%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 575px) {
    max-width: 100%;
    font-size: 20px;
  }
}
.blsub {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  color: #000;
  max-width: 74%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 575px) {
    max-width: 100%;
    font-size: 18px;
  }
}
.wtlvdo {
  width: 100%;
  max-width: 700px;
  padding-bottom: 25%;
  position: relative;
  height: 0;
  margin: 45px auto;
  box-shadow: 0px 0px 30px -2px #000000;
  min-height: 400px;
  & video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}
.getStartedLink {
  @apply --marketplaceButtonStyles;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  padding: 8px 8px;
  min-height: 34px;
  width: 280px;
  color: #fff;
  margin: 30px auto;
  &:hover {
    background: linear-gradient(90deg, rgba(146, 27, 152, 1) 27%, rgba(201, 59, 84, 1) 72%);

    /* color: var(--marketplaceColorDark); */
  }
}
.revCardCont {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px auto;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.revCrd {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 20px;
  box-shadow: 0px 0px 30px -2px #cecece;
  padding: 25px;
  border: 1px solid #cecece;
  max-width: 300px;
  & p {
    flex-grow: 1;
    text-align: center;
    color: #000;
  }
  & p:last-child {
    font-weight: 700;
    flex-grow: unset;
    margin-top: auto;
  }
  & img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    max-width: unset;
  }
}
.whiteSection {
  background-color: var(--matterColorLight);
}
.tssliderrow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 50px auto;
  & > div {
    flex-basis: 40%;
    flex-shrink: 0;
  }
  @media (max-width: 575px) {
    flex-direction: column-reverse;
    margin: 20px auto;
    gap: 20px;
    & > div {
      padding: 0 20px;
    }
  }
}
.descp {
  font-size: 18px;
  color: #232323;
  text-align: center;
  max-width: 74%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    margin-bottom: 20px;
    max-width: 100%;
  }
}
.benifitContent {
  & p {
    text-align: left !important;
    font-size: 18px;
    color: #232323;
  }
}
.cardHeading {
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  color: #c93b54;
  position: relative;
  margin-bottom: 30px;
  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 38px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 34px;
  }
  &::after {
    position: absolute;
    width: 90px;
    height: 5px;
    background-color: #757575;
    content: '';
    bottom: -5px;
    left: 0;
  }
}
.cardImg {
  max-width: 420px;
  flex-basis: 40%;
  flex-shrink: 0;
  @media (max-width: 767px) {
    max-width: 340px;
  }
  @media (max-width: 575px) {
    max-width: 100%;
  }
}
.needcont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
  row-gap: 40px;
  margin: 50px auto;
  @media (max-width: 991px) {
    gap: 30px;
  }
}
.needcrd {
  flex-basis: 30%;
  padding: 25px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #cecece;
  border-radius: 20px;
  align-items: center;
  box-shadow: 0px 0px 10px 2px #cecece;
  & p:first-child {
    font-weight: 700;
    color: #000;
    margin-top: 0px;
    font-size: 18px;
    text-align: center;
  }
  & p {
    color: #000;
    line-height: 30px;
  }
  @media (max-width: 991px) {
    flex-basis: 40%;
    max-width: 380px;
  }
  @media (max-width: 575px) {
    flex-basis: 100%;
    max-width: unset;
  }
}
.perkCrd {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 30px 0;
  & > div {
    flex-basis: 45%;
    max-width: 45%;
    flex-shrink: 0;
    flex-grow: 1;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 0;
    & > div {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
.revPrkcrd {
  flex-direction: row-reverse;
  & .plogo {
    right: unset !important;
    left: 15px !important;
  }
  & .secImg {
    right: 15px !important;
    left: unset !important;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.perkDiv1 {
  & > p {
    text-align: left !important;
  }
  & p:first-child {
    color: #c93b54;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 22px;
  }
  & p:nth-child(2) {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 35px;
    line-height: 48px;
    color: #000;
  }
  & p:nth-child(3) {
    color: #000;
    font-size: 18px;
    margin-bottom: 35px;
  }
  & ul {
    & li {
      font-size: 20px;
      color: #000;
      margin-bottom: 20px;
      display: flex;
      gap: 10px;
      & svg {
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        display: block;
        & path {
          stroke: var(--successColor);
        }
      }
    }
  }
  @media (max-width: 767px) {
    & p:nth-child(2) {
      font-size: 34px;
      line-height: 44px;
    }
    & p:first-child {
      font-size: 21px;
    }
    & ul {
      & svg {
        width: 25px;
        height: 25px;
      }
      & li {
        font-size: 20px;
      }
    }
  }
}
.perkDiv2 {
  & .mainImg {
    width: 100%;
  }
  & .secImg {
    position: absolute;
    width: 50%;
    left: 15px;
    transform: translate(0px, 55%);
    bottom: 0;
  }
  & .plogodv {
    position: relative;
    width: 100%;
    @media (max-width: 767px) {
      margin-bottom: 240px !important;
    }
  }
  & .plogo {
    position: absolute;
    bottom: -67px;
    right: 15px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background-color: var(--successColor);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & svg {
      width: 30px;
      height: 30px;
    }
  }
}
.sectionWithLayout {
  display: flex;
  flex-direction: column;
  /* & > div:first-child {
    margin-bottom: 70px;
  } */
  @media (max-width: 767px) {
    /* margin-bottom: 200px; */
  }
}
.hwitwcont {
  display: flex;
  justify-content: space-evenly;
  padding: 60px 0 40px;
  gap: 30px;
  flex-wrap: wrap;
  row-gap: 80px;
}
.hwitwcrd {
  flex-basis: 300px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, #921b98 72%);
  border-radius: 20px;
  position: relative;
  border: 2px solid transparent;
  & span {
    position: absolute;
    color: #fff;
    font-weight: 600;
    background-color: #c93b54;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    @media (max-width: 575px) {
      width: 65px;
      height: 65px;
    }
  }
  & p {
    color: #000;
    background-color: #fff;
    border-radius: 20px;
    margin: 0;
    font-weight: 600;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
    @media (max-width: 575px) {
      padding-top: 40px;
    }
  }
}
.pricingLogo {
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border-radius: 50%;
  color: #fff;
  width: 150px;
  height: 150px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px solid #c93b54;
  & p {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }
  & p:last-child {
    font-weight: 500;
    font-size: 14px;
  }
}
.planCont {
  display: flex;
  justify-content: center;
  gap: 50px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    & .planCrd:first-child {
      margin-top: 30px;
    }
  }
}
.planCrd {
  flex-basis: 50%;
  padding: 25px;
  border: 3px solid #921b98;
  max-width: 420px;
  box-shadow: 0px 0px 15px 6px #cecece;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 60px;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    flex-basis: auto;
  }
  & .planHead {
    font-size: 30px;
    color: #921b98;
    text-align: center;
    font-weight: 700;
  }
  & ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 40px;
    & li {
      font-weight: 700;
      color: #000;
    }
  }
  & a {
    margin-top: auto;
  }
  & span {
    margin-top: auto;
  }
}
.freeplncrd {
  border: 3px solid var(--successColor);
  & .planHead {
    color: var(--successColor);
  }
}
.planInfo {
  max-width: 360px;
  margin: 10px auto 25px;
  color: #000;
  text-align: center;
  line-height: 28px;
}
.planPrice {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 10px auto 25px;

  & span {
    color: #9f9f9f;
  }
}
.planIncludes {
  color: #000;
  font-weight: 600;
  font-size: 17px;
  position: relative;
  margin-bottom: 25px;
  &::after {
    position: absolute;
    width: 55px;
    height: 3px;
    content: '';
    bottom: -5px;
    left: 0;
    background-color: var(--successColor);
  }
}
.mplanIncludes {
  color: #c93b54;
  font-weight: 600;
  font-size: 17px;
  position: relative;
  margin-bottom: 25px;
  &::after {
    position: absolute;
    width: 55px;
    height: 3px;
    content: '';
    bottom: -5px;
    left: 0;
    background-color: #921b98;
  }
  & span {
    color: var(--successColor);
  }
}
.switchCont {
  position: absolute;
  display: flex;
  justify-content: center;
  top: -50px;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 10px;
  & > span {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
}
.switchBtn {
  padding: 0;
  border: 0;
  cursor: pointer;
}
.switchBody {
  width: 40px;
  display: block;
  height: 20px;
  border: 1px solid #000;
  border-radius: 20px;
  position: relative;
}
.switch {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 100%;
  border-radius: inherit;
  transform: translate(0, -50%);
  background-color: var(--successColor);
}
.monthActSwitch {
  right: 0;
  left: unset;
}
.annualActSwitch {
  left: 0;
  right: unset;
}
.ftfCont {
  background-color: #ececec;
  padding: 50px;
  margin: 60px 0;
  & p {
    text-align: left;
    max-width: 80%;
    color: #000;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 575px) {
      max-width: 100%;
    }
  }
  & p:first-child {
    color: #c93b54;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    @media (max-width: 575px) {
      font-size: 32px;
      max-width: 100%;
    }
  }
}
.planSecCont {
  margin-bottom: 80px;
}
.furthrTogathr {
  display: flex;
  padding: 40px 0 10px;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    & img {
      width: 90% !important;
    }
  }
  & img {
    width: 50%;
  }
  & p {
    font-weight: 700;
    max-width: 48%;
    @media (max-width: 767px) {
      max-width: 90%;
    }
  }
  & div {
    display: flex;
    flex-direction: column;
  }
}
.helpedLogosCont {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  & img {
    object-fit: contain;
    flex-basis: 20%;
    max-width: 20%;
    padding: 0 5px;
    flex-shrink: 0;
  }
}
.greySection {
  background-color: #f2f1ef !important;
}
.sectionIconsSlider {
  padding: 0 0 25px;
  text-align: center;
  margin-top: 40px;
  & img {
    object-fit: contain;
    width: 150px;
    padding: 0 15px;
  }
}
.consultContainer {
  display: flex;
  flex-direction: column;

  & h3 {
    width: 100%;
    text-align: center;
    max-width: 480px;
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
    margin: 0 auto 10px;
    @media (max-width: 991px) {
      font-size: 35px;
    }
    @media (max-width: 575px) {
      margin-bottom: 15px;
      max-width: 100%;
      line-height: 34px;
      font-size: 25px;
    }
  }

  & img {
    display: block;
    width: 144px;
    height: 144px;
    margin: 12px auto;
  }

  & span {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
  }

  & .button {
    width: 264px;
    padding: 8px;
    display: block;
    margin: 20px auto;
    border: 0px;
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(-90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
      text-decoration: none;
    }
  }
}
