.ndv {
  max-width: 920px;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 0 150px;
  @media (max-width: 767px) {
    padding: 15px 0 50px;
  }
}
.ndv1 {
  background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
  & h3 {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 70px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
}
.ndv4,
.ndv2,
.ndv3 {
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  & span:nth-child(2) {
    color: #c93b54;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    @media (max-width: 575px) {
      text-align: center;
    }
  }
  & span:last-child {
    font-size: 15px;
    line-height: 24px;
    color: #000;
    flex-grow: 1;
    @media (max-width: 575px) {
      text-align: center;
    }
  }
  & svg {
    margin: auto;
    display: block;
    width: 25px;
    height: 25px;
  }
}
.decoy:nth-child(2) {
  /* flex-basis: 30% !important; */
}
.decoy {
  background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
  border: 3px solid transparent;
  margin: 0 10px;
  flex-basis: 31%;
}
.decoyconts {
  position: absolute;
  display: flex;
  top: 80px;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
    background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
    position: relative;
    top: unset;
    & > div {
      margin-top: 7px;
      margin-bottom: 7px;
    }
  }
}
.n1dv {
  & h3 {
    color: #000;
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 20px;
  }
}
.links {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  & a {
    color: rgb(31, 31, 151);
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    &:hover {
      text-decoration: none;
    }
  }
}
.n2dv {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  max-width: 856px;
  margin: auto;
  & > div {
    display: flex;
    border-bottom: 1px solid black;
    & p:first-child {
      flex-basis: 35%;
      margin: 0;
      border-right: 1px solid black;
      padding: 20px;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      display: flex;
      align-items: center;
      & img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
      @media (max-width: 767px) {
        flex-basis: 50%;
      }
    }
    & p:last-child {
      flex-basis: 65%;
      margin: 0;
      padding: 20px;
      font-size: 17px;
      font-weight: 700;
      color: #c93b54;
      @media (max-width: 767px) {
        flex-basis: 50%;
      }
    }
  }
  & > div:last-child {
    border-bottom: 0;
  }
}
