.startBusinessContainer {
  display: flex;
  flex-direction: row;
  width: calc(100% - 2rem);
  max-width: 856px;
  margin: 16px auto;
  background-color: #fff;
  padding: 20px;
  align-items: center;
  & img {
    flex-basis: 60%;
    width: 60%;
    height: 100%;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    & span {
      margin-bottom: 18px;
      font-size: 22px;
      font-weight: 500;
      font-family: 'Stardos Stencil', cursive;
      @media (min-width: 1024px) {
        margin-bottom: 40px !important;
        font-size: 34px !important;
      }
      @media (min-width: 768px) {
        margin-bottom: 30px;
        font-size: 24px;
      }
    }

    & span:first-child {
      color: #35b36c;
    }
    & span:nth-child(2) {
      color: #2dadb9;
    }
    & span:last-child {
      color: #c93b55;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    & img {
      flex-basis: 100%;
      width: 100%;
    }
  }
}

.businessStartStep {
  margin-top: 56px;
  padding: 0rem 1rem;
  width: 100%;
  max-width: 856px;
  margin-left: auto;
  margin-right: auto;
  & > h3 {
    color: #c93b54;
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    line-height: 45px;
  }
  & > span {
    display: block;
    width: 100%;
    max-width: 560px;
    text-align: center;
    font-weight: 600;
    line-height: 1.5rem;
    margin: 0 auto 20px;
  }
}
.stepInfoContainer {
  display: flex;
  flex-direction: column;
  & .stepInfo:nth-child(2) {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
  }
  & .stepInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0px;

    & img {
      width: 50%;
      padding: 20px;
      box-shadow: 0px 0px 10px 10px #cecece;
      border-radius: 40px;
      @media (max-width: 768px) {
        width: 60%;
      }
      @media (max-width: 575px) {
        width: 80%;
      }
    }

    & div {
      flex-basis: 40%;
      display: flex;
      flex-direction: column;

      & > span:first-child {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 14px;
        color: #000;

        & > span:first-child {
          color: #c93b55;
        }
      }
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;

      & img {
        margin-top: 16px;
      }
    }
  }
}
